import { noop } from "lodash-es";
import { ComponentProps } from "react";
import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { Skeleton } from "components/Skeleton";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import { LockFilled } from "components/design-system/icons/all-icons";
import FacePile from "components/design-system/ui/FacePile";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import GroupProfileModal from "components/group/GroupModal/GroupProfileModal";
import {
  currentPathWithoutDrawer,
  routeToGroup,
} from "components/routing/utils";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";
import { WorkspaceModal } from "components/workspace/WorkspaceModal";
import { useGetRecipientExternalData } from "hooks/useGetRecipientExternalData";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import { formatGroupName } from "utils/group/formatGroupName";

import Header from "./Header";
import Main from "./Main";

type Props = { groupID: string } & Pick<ComponentProps<typeof Header>, "pane">;

export default function GroupProfilePane({ groupID, pane }: Props) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();
  const {
    actionPending,
    cancelRequestToJoin,
    joinGroup,
    joinRequested,
    requestToJoin,
    loading: groupMembershipLoading,
  } = useGroupMembership(groupID);

  const { data: groupData } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: `${groupID}-${authData?.me.id}`,
    },
  });

  const edge = nodeAs(groupData?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);
  const { memberEdge } = useMemberEdge(edge);
  const node = edge?.node;

  const group = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"])?.node;
  const workspace = nodeAs(edge, [
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ])?.node;

  const { tooltip } = useGetRecipientExternalData(node);
  const { name: groupName, emoji } = formatGroupName(node);
  const description = group?.description;
  const totalCount = node?.members.totalCount || 0;
  const users = node?.members.edges.map(e => e.node) || [];

  const {
    buttonAction,
    buttonText,
    buttonStyle,
  }: Pick<
    ComponentProps<typeof Main>,
    "buttonStyle" | "buttonText" | "buttonAction"
  > = (() => {
    if (!edge || memberEdge) {
      return {
        buttonAction: () =>
          history.push(routeToGroup({ groupID, to: "canonical" })),
        buttonText: "View",
        buttonStyle: "primary",
      };
    }

    if (joinRequested) {
      return {
        buttonAction: cancelRequestToJoin,
        buttonText: "Undo request",
        buttonStyle: "secondary",
      };
    }

    if (joinGroup) {
      return {
        buttonAction: joinGroup,
        buttonText: "Join",
        buttonStyle: "primary",
      };
    }

    return {
      buttonAction: requestToJoin,
      buttonText: "Request",
      buttonStyle: "primary",
    };
  })();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openGroupSettingsModal =
    group?.__typename === "Group"
      ? () => {
          openModal(<GroupProfileModal groupID={group.id} />);
        }
      : workspace?.__typename === "Workspace"
        ? () => {
            openModal(<WorkspaceModal workspaceID={workspace.id} />);
          }
        : noop;

  const isLoading = !node || groupMembershipLoading;
  const workspaceID = group?.workspaceID ?? workspace?.id;

  return (
    <>
      <Header
        emoji={{ emoji: emoji }}
        loading={isLoading}
        avatarName={groupName}
        avatarURL={workspace?.avatarURL ?? undefined}
        onClick={() => history.push(currentPathWithoutDrawer())}
        pane={pane}
        title={
          !isLoading ? (
            <>
              {groupName}
              {buttonText === "Request" ? (
                <LockFilled
                  className="ml-8 text-icon-secondary"
                  width={20}
                  height={20}
                />
              ) : null}
            </>
          ) : (
            <Skeleton width="80px" height="28px" />
          )
        }
      >
        <div className="flex mt-8 items-center">
          <button
            onClick={openGroupSettingsModal}
            className="hidden mr-8 md:flex"
          >
            <FacePile
              hasExternal={!!tooltip}
              tooltip={tooltip}
              totalCount={totalCount}
              users={isLoading ? undefined : users.slice(0, 3)}
            />
          </button>
        </div>
      </Header>
      <ViewScrollContainer padding="pb-16">
        <Main
          description={{ text: description || undefined, title: "Description" }}
          buttonAction={buttonAction}
          loading={isLoading}
          secondaryButton={
            group?.__typename === "Group"
              ? { onClick: openGroupSettingsModal, text: "Group settings" }
              : undefined
          }
          buttonStyle={buttonStyle}
          buttonText={
            actionPending ? (
              <LoadingSpinner
                className={
                  "w-20 h-20 m-auto text-icon-secondary animate-spin transition-opacity opacity-100"
                }
              />
            ) : (
              buttonText
            )
          }
          workspaces={workspaceID ? [workspaceID] : []}
        />
      </ViewScrollContainer>
    </>
  );
}
