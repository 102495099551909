import { Flipped, spring } from "react-flip-toolkit";

import { flipSpring } from "components/Animated/utils";
import SwipeAction from "components/design-system/ui/SwipeAction/SwipeAction";
import useUpdatingState from "hooks/useUpdatingState";
import { ComponentProps, useEffect } from "react";
import useAppStateStore from "store/useAppStateStore";
import { deviceHasMouse } from "utils/deviceHasMouse";
import tw from "utils/tw";

const hasMouse = deviceHasMouse();

type Props = {
  canFollow?: boolean;
  className?: string;
  flipId?: string;
  onClick?: (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => void;
  selected?: boolean;
} & ComponentProps<typeof SwipeAction>;

const animate = (el: HTMLElement, i: number, removeItem?: () => void) =>
  spring({
    config: flipSpring,
    delay: i * 15,
    onComplete: removeItem,
    onUpdate: value => {
      if (typeof value === "number") return;
      const { opacity } = value;
      el.style.opacity = `${opacity}`;
    },
    values: {
      opacity: removeItem ? [1, 0] : [0, 1],
    },
  });

/**
 * @param children Content of the item
 * @param className Classes applied to the li element
 * @param flipId ID used to match the element between Flipper and Flipped
 * @param onClick Use this to trigger an additional effect after clicking the link or overriding the default action.
 * @param selected Determines if the element is selected
 * @param setSwipedOpenItemId providing this function wraps swipeable thread actions
 * around the children
 * @param canArchive enables right swipe with dismiss action e.g. delete draft or archive
 */

const SectionItem = ({
  canFollow,
  children,
  className,
  flipId,
  onClick,
  selected: isSelectedProp,
  ...props
}: WithChildren<Props>): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  // Hover is not supported in mobile devices, this is a way to provide a visual indicator about the pressed item
  const [isSelected, setIsSelected] = useUpdatingState(isSelectedProp);

  useEffect(() => {
    if (breakpointMD) return; // Only reset the selected state on mobile
    setIsSelected(false);
  }, [breakpointMD, props.swipedOpenItemId, setIsSelected]);

  return (
    <Flipped
      flipId={flipId}
      onAppear={(el, i) => animate(el, i)}
      onExit={(_el, _i, remove) => remove()}
      spring={flipSpring}
    >
      <li className="list-none">
        <SwipeAction
          borderRadius="rounded-md"
          canFollow={canFollow}
          height="h-44 md:h-32"
          actionBarPadding="py-1"
          {...(!deviceHasMouse() && props.setSwipedOpenItemId
            ? {
                setSwipedOpenItemId: props.setSwipedOpenItemId,
                swipedOpenItemId: props.swipedOpenItemId,
                ...("canArchive" in props
                  ? {
                      canArchive: props.canArchive,
                      dismissOnRightSwipe: !!props.dismissOnRightSwipe,
                      itemData: props.itemData,
                    }
                  : { itemData: props.itemData }),
              }
            : { setSwipedOpenItemId: undefined })}
        >
          <div
            className={tw(
              "group/item relative",
              "flex items-center rounded-md select-none",
              "min-w-[32px] w-full h-44 md:h-32",
              "cursor-pointer focus-visible-shadow truncate",
              "text-text-secondary hover:bg-background-list-hover",
              {
                "!bg-background-list-selected !text-text-primary": isSelected,
              },
              className
            )}
            data-testid="sectionItem"
            onKeyDown={e => e.key === "Enter" && onClick?.(e)}
            onPointerCancel={() => {
              if (hasMouse) return;
              setIsSelected(isSelectedProp);
            }}
            onPointerDown={e => {
              if (hasMouse) return onClick?.(e);
              setIsSelected(true);
            }}
            onPointerUp={() => {
              if (hasMouse) return;
              setIsSelected(false);
            }}
            onClick={e => {
              // TODO: Merge handlers,
              // this click handler might be eliminated and its logic
              // could be integrated into the pointer handlers

              if (hasMouse) return;
              setIsSelected(isSelectedProp);
              props.setSwipedOpenItemId?.(undefined);
              onClick?.(e);
            }}
            tabIndex={0}
          >
            {children}
          </div>
        </SwipeAction>
      </li>
    </Flipped>
  );
};

export default SectionItem;
