import { memo } from "react";

import { StreamFileAttachment } from "@utility-types";

import { Video as VideoElement } from "components/Media";

type Props = {
  files: StreamFileAttachment[];
};

function Video({ files }: Props): JSX.Element {
  return (
    <div>
      {files.map(file => (
        <figure key={file.file_id} className="my-6 w-full">
          <VideoElement
            className="w-full max-w-[445px] h-250 rounded-lg border-1 border-border-container hover:border-border-container-hover"
            src={file.asset_url}
            title={file.title}
            type={file.mime_type}
            controls
          />
          {/* TODO: adjust/add styles for captions  */}
          {/* <figcaption>{file.title}</figcaption> */}
        </figure>
      ))}
    </div>
  );
}

export default memo(
  Video,
  (prev, next) =>
    prev.files.map(({ file_id }) => file_id).toString() ===
    next.files.map(({ file_id }) => file_id).toString()
);
