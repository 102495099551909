import { useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";

import {
  MemberConnection,
  Recipient,
  Workspace,
  WorkspacePreview,
  nodeIs,
} from "@utility-types";
import { FetchWorkspaceDocument, FetchWorkspaceQuery } from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";
import useValidateRecipientOrigin from "hooks/useValidateRecipientOrigin";
import useAuthData from "./useAuthData";

export const useGetRecipientExternalData = (
  recipient?: Recipient & { members?: MemberConnection }
) => {
  const [recipientWorkspace, setRecipientWorkspace] = useState<
    Workspace | WorkspacePreview
  >();

  const { authData } = useAuthData();
  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();
  const isGroup =
    recipient?.__typename === "Group" ||
    recipient?.__typename === "GroupPreview";

  const { containsExternalRecipients } = useValidateRecipientOrigin();
  const { externalRecipients } = containsExternalRecipients(
    [
      isGroup && recipient.members?.edges.length
        ? recipient.members.edges.map(edge => edge.node) ?? []
        : recipient ?? [],
    ].flat(),
    true
  );

  useEffect(() => {
    if (!recipient) return;
    const workspaceID =
      recipient.__typename === "Workspace" ||
      recipient.__typename === "WorkspacePreview"
        ? recipient.id
        : "workspaceID" in recipient
          ? recipient.workspaceID
          : recipient.workspaceIDs?.[0];
    if (!workspaceID) return;
    apolloClient
      .query<FetchWorkspaceQuery>({
        query: FetchWorkspaceDocument,
        variables: { id: workspaceID },
      })
      .then(response => {
        if (nodeIs(response.data.node, ["Workspace", "WorkspacePreview"])) {
          isMounted.current && setRecipientWorkspace(response.data.node);
        }
      });
  }, [apolloClient, isMounted, recipient]);

  if (!recipient) {
    return {
      label: undefined,
      tooltip: undefined,
      groupType: undefined,
    };
  }

  const isInternalGroup =
    isGroup &&
    !!authData?.me.workspaceIDs.includes(recipient.workspaceID ?? "");

  if (!externalRecipients?.length) {
    return {
      label: undefined,
      tooltip: undefined,
      groupType: isInternalGroup ? "internal" : undefined,
    };
  }

  let tooltip = "";
  let label = "";

  const fromWorkspaceAndDomainLabel = recipientWorkspace?.domains?.[0]
    ? `From ${recipientWorkspace.name} ${
        recipientWorkspace.domains?.[0]
          ? `(${recipientWorkspace.domains?.[0]})`
          : ""
      }`
    : "From outside your workspace";

  const recipientWorkspaceDomain = recipientWorkspace?.domains?.[0];
  const groupAndWorkspaceLabel = recipientWorkspaceDomain
    ? recipientWorkspaceDomain
    : "External";

  switch (recipient.__typename) {
    case "User":
      tooltip = fromWorkspaceAndDomainLabel;
      label = recipient.addressDomains?.[0]
        ? recipient.addressDomains?.[0]
        : "External";
      break;
    case "Group":
    case "GroupPreview":
      tooltip = isInternalGroup
        ? `Includes guests from outside ${recipientWorkspace?.name} ${
            recipientWorkspaceDomain ? `(${recipientWorkspaceDomain})` : ""
          }`
        : fromWorkspaceAndDomainLabel;
      label = groupAndWorkspaceLabel;
      break;
    case "Workspace":
    case "WorkspacePreview":
      tooltip = "You're not a member of this workspace";
      label = groupAndWorkspaceLabel;
      break;
  }

  return {
    label,
    groupType: isInternalGroup ? "internalWithExternalUsers" : undefined,
    tooltip,
  };
};
