import { memo } from "react";

import { FileType, StreamLinkPreviewAttachment } from "@utility-types";
import { matchURL } from "utils/matchURL";

import { Image as ImageElement } from "components/Media";
import { Hyperlink } from "components/MessageElements";

import AttachmentError from "../AttachmentError";

import AudioPreview from "./AudioPreview";
import InformationSummary from "./InformationSummary";
import VideoPreview from "./VideoPreview";

type Props = {
  compact?: boolean;
  externalObjects: StreamLinkPreviewAttachment[];
};

const Card = memo(UnMemoizedCard);

export default function ExternalObject({
  compact = false,
  externalObjects,
}: Props): JSX.Element {
  return (
    <div>
      {externalObjects.map(externalObject => (
        <Card key={externalObject.id} compact={compact} {...externalObject} />
      ))}
    </div>
  );
}

const mediaType = Object.values<string>(FileType);
const ignoreUnderlineTitleInMediaForTypes = ["audio", "video"];

function UnMemoizedCard({
  audio,
  compact = false,
  description,
  icon,
  image,
  objectType,
  title,
  url,
  video,
}: StreamLinkPreviewAttachment & {
  compact: boolean;
}): JSX.Element | null {
  const thumbnail = image?.[0];
  const isImage = objectType === "image";
  const isOther = !mediaType.includes(objectType);
  const leftAligned = isOther || isImage;

  if (!title || !matchURL(url)) {
    return (
      <AttachmentError message="Unable to display attachment. Check for an updated version of Glue." />
    );
  }

  return (
    <div
      className={`max-w-full my-6 overflow-hidden border rounded-lg select-none border-border-container hover:border-border-container-hover ${
        !ignoreUnderlineTitleInMediaForTypes.includes(objectType) && "group"
      }`}
    >
      <Hyperlink
        className={`hover:no-underline flex ${
          leftAligned ? "flex-row" : "flex-col"
        } min-w-0 !text-text-subtle`}
        url={url}
      >
        {objectType === "video" && video?.[0] ? (
          <VideoPreview image={thumbnail?.url} title={title} video={video[0]} />
        ) : thumbnail ? (
          <ImageElement
            blurHash={thumbnail?.blurHash ?? undefined}
            className={`w-full h-full ${
              leftAligned ? "object-cover object-left" : "object-contain"
            }`}
            figureClassName={`flex ${
              leftAligned && compact
                ? "h-[78px] w-[78px] shrink-0"
                : isOther
                  ? "h-[126px] w-[126px] shrink-0"
                  : isImage
                    ? "h-[126px] w-[126px] max-w-[254px] grow"
                    : "h-[190px] w-full shrink-0"
            }`}
            onClick={e => objectType === "audio" && e.preventDefault()}
            resize={{
              fit: "max",
              h: leftAligned ? (compact ? 78 : 126) : 190,
            }}
            src={thumbnail?.url}
          >
            {audio?.[0] && (
              <AudioPreview
                audio={audio[0]}
                className="flex absolute justify-center items-center w-full h-full"
              />
            )}
          </ImageElement>
        ) : (
          audio?.[0] && (
            <AudioPreview
              audio={audio[0]}
              className="mt-6 ml-6 w-11/12 min-w-0 opacity-95"
            />
          )
        )}
        <InformationSummary
          compact={compact}
          description={description}
          icon={icon}
          title={title}
          url={url}
        />
      </Hyperlink>
    </div>
  );
}
