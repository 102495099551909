import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";

const RecentlyShared = () => {
  const _ListItem = ({
    avatarURL,
    name,
  }: { avatarURL: string; name: string }) => {
    return (
      <li className="h-32 flex items-center ml-4">
        <Avatar className="mx-2" size="tiny" avatarURL={avatarURL} />
        <span className="ml-4 text-subhead truncate">{name}</span>
      </li>
    );
  };
  return (
    <div className="bg-background-body rounded-lg shadow-level1">
      <div className="flex items-center justify-between h-38 px-16 pt-12 pb-8 text-footnote-bold">
        Recently shared
        <Icon
          className="text-icon-secondary cursor-pointer"
          icon="ArrowRight"
          size={16}
        />
      </div>
      <ul className="pl-12 pr-16 pb-8">{/* ListItems */}</ul>
    </div>
  );
};

export default RecentlyShared;
