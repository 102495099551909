import { useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Recipient, nodeIs } from "@utility-types";
import { FetchWorkspaceDocument, FetchWorkspaceQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import isDefined from "utils/isDefined";

type CommonWorkspace = {
  id: string;
  name: string;
  domain?: string;
};

export const useGetCommonWorkspaces = (recipient?: Recipient) => {
  const { authData } = useAuthData();
  const apolloClient = useApolloClient();

  const [commonWorkspaces, setCommonWorkspaces] = useState<CommonWorkspace[]>(
    []
  );

  useEffect(() => {
    (async () => {
      if (!recipient) return;

      const commonWorkspacesIDs =
        "workspaceIDs" in recipient
          ? recipient.workspaceIDs?.filter(workspaceID =>
              authData?.me.workspaceIDs?.includes(workspaceID)
            )
          : authData?.me.workspaceIDs?.includes(recipient.workspaceID ?? "")
            ? [recipient.workspaceID]
            : undefined;

      if (!commonWorkspacesIDs) return [];

      const commonWorkspaces = await Promise.all(
        commonWorkspacesIDs.map(workspaceID =>
          apolloClient
            .query<FetchWorkspaceQuery>({
              fetchPolicy: "cache-first",
              query: FetchWorkspaceDocument,
              variables: { id: workspaceID },
            })
            .then(response => {
              if (nodeIs(response.data.node, ["Workspace"])) {
                return {
                  id: response.data.node.id,
                  name: response.data.node.name,
                  domain: response.data.node.domains[0],
                };
              }
            })
        )
      );

      setCommonWorkspaces(commonWorkspaces.filter(isDefined));
    })();
  }, [apolloClient, authData?.me, recipient]);

  return commonWorkspaces;
};
