import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { FetchResult } from "@apollo/client";
import { Icon } from "components/design-system/icons";

import {
  JoinApproval,
  Workspace,
  WorkspacePreview,
  nodeAs,
} from "@utility-types";
import CreateIllustrationWorkspaces from "assets/onboarding/Create_Illustration_Workspaces.png";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import {
  ApproveJoinApprovalMutation,
  AuthConfigDocument,
  FetchJoinApprovalsDocument,
  FetchPendingJoinApprovalsDocument,
  FetchWorkspaceOrPreviewEdgeDocument,
  JoinWorkspaceMutation,
  NotificationsDocument,
  WorkspacesAndGroupsListDocument,
  useApproveJoinApprovalMutation,
  useFetchDomainsQuery,
  useFetchJoinApprovalsQuery,
  useIgnoreJoinApprovalMutation,
  useJoinWorkspaceMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import WorkspaceListItem from "./WorkspaceListItem";

import { StandardModal } from "components/Modals";
import {
  NotificationPreAlertModal,
  hasDefaultPermission,
} from "components/Notification";
import { routePath, routeToGroup } from "components/routing/utils";

import { WorkspaceModalCreate } from "..";

const WorkspacesSection = ({
  children,
  heading,
}: {
  children: React.ReactNode;
  heading: string;
}) => (
  <div className="mb-16 bg-contain border-b-1 border-interactive-ghost">
    <div className="mb-16">{heading}</div>
    <ul>{children}</ul>
  </div>
);

const WorkspaceModalJoin = ({
  onboarding,
  ...props
}: ModalProps & { onboarding?: boolean }): JSX.Element => {
  const { authReady, fetchAuthData } = useAuthData();
  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({
      closeModal,
      openModal,
    })
  );
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [joinWorkspace] = useJoinWorkspaceMutation({
    errorPolicy: "all",
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const { data: joinApprovals } = useFetchJoinApprovalsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
  });

  const mutationOptions = {
    awaitRefetchQueries: true,
    errorPolicy: "all" as const,
    refetchQueries: [
      FetchJoinApprovalsDocument,
      FetchPendingJoinApprovalsDocument,
      NotificationsDocument,
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ],
  };

  const [approveJoin] = useApproveJoinApprovalMutation(mutationOptions);
  const [ignoreJoin] = useIgnoreJoinApprovalMutation(mutationOptions);

  const { data } = useFetchDomainsQuery({
    fetchPolicy: "cache-and-network",
  });

  const workspaces = data?.domains.edges.flatMap(
    domain => domain.node.workspaces?.edges
  );

  const handleCloseModal = useCallback(() => {
    closeModal(`${props.modalId}`);
  }, [closeModal, props.modalId]);

  const onJoin = useCallback(
    (workspace: WorkspacePreview | Workspace, joinApproval?: JoinApproval) => {
      const redirectToWorkspace = (
        promise: Promise<
          FetchResult<ApproveJoinApprovalMutation | JoinWorkspaceMutation>
        >,
        id: string
      ) => {
        setIsSubmitting(true);

        return promise
          .then(({ data }) => {
            if (!data) return;
            fetchAuthData({ refresh: true });
            handleCloseModal();
            if (onboarding) {
              history.push(routePath({ superTab: "inbox" }));
            } else {
              history.push(routeToGroup({ groupID: id }));
            }
          })
          .catch(err => {
            console.warn("Error: [deleteWorkspace] - ", err);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      };

      if (joinApproval) {
        return redirectToWorkspace(
          approveJoin({ variables: { joinApprovalID: joinApproval.id } }),
          joinApproval.joinable.id
        );
      }

      return redirectToWorkspace(
        joinWorkspace({ variables: { id: workspace.id } }),
        workspace.id
      );
    },
    [
      approveJoin,
      fetchAuthData,
      handleCloseModal,
      history,
      joinWorkspace,
      onboarding,
    ]
  );

  const onIgnore = useCallback(
    (joinApproval: JoinApproval) => {
      setIsSubmitting(true);
      ignoreJoin({ variables: { joinApprovalID: joinApproval.id } })
        .catch(err => {
          console.warn("Error: [deleteWorkspace] - ", err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [ignoreJoin]
  );

  const handleFinishOnboarding = async () => {
    if (!onboarding || !(await hasDefaultPermission())) return;
    openModal(<NotificationPreAlertModal />);
  };

  const openCreateWorkspaceModal = useCallback(() => {
    openModal(<WorkspaceModalCreate />);
  }, [openModal]);

  const invitations = joinApprovals?.joinApprovals.edges.filter(
    j => j.node.joinable.__typename === "WorkspacePreview"
  );

  const invitationSections = !!invitations?.length && (
    <WorkspacesSection heading="You've been invited to join:">
      {invitations.map(i => {
        const workspace = nodeAs(i.node.joinable, ["WorkspacePreview"]);
        return (
          workspace && (
            <WorkspaceListItem key={workspace?.id} workspace={workspace}>
              <div className="flex flex-row">
                <Button
                  buttonStyle="subtle"
                  className="mr-24 !p-0 border-none"
                  disabled={isSubmitting}
                  onClick={() => onIgnore(i.node)}
                  type="button"
                >
                  Decline
                </Button>
                <Button
                  buttonStyle="secondary"
                  disabled={isSubmitting}
                  onClick={() => onJoin(workspace, i.node)}
                  type="button"
                >
                  Accept invite
                </Button>
              </div>
            </WorkspaceListItem>
          )
        );
      })}
    </WorkspacesSection>
  );

  const workspacesAvailable = workspaces && workspaces.length > 0 && (
    <WorkspacesSection heading="Your coworkers are already here:">
      {workspaces
        .filter(
          wks =>
            invitations?.findIndex(i => i.node.joinable.id === wks?.node.id) ===
            -1
        )
        .map(edge => (
          <WorkspaceListItem key={edge?.node?.id} workspace={edge?.node}>
            {edge?.node.__typename === "Workspace" ? (
              <div className="text-interactive-subtle-disabled flex items-center font-bold">
                <Icon icon="Check" size={16} strokeWidth={3} />
                &nbsp; Joined
              </div>
            ) : (
              <Button
                buttonStyle="secondary"
                disabled={isSubmitting}
                onClick={() => onJoin(edge?.node)}
                type="button"
              >
                Join
              </Button>
            )}
          </WorkspaceListItem>
        ))}
    </WorkspacesSection>
  );

  const invitationsOrWorkspaces = !!(invitations || workspacesAvailable);

  const workspacesDescription =
    "Workspaces are shared environments for teams, consisting of users and groups.";

  return (
    <StandardModal
      afterClose={() => handleFinishOnboarding()}
      contentHandlesSafeArea={false}
      header={
        data &&
        (invitationsOrWorkspaces ? (
          <Header variant="bordered">
            <h3 className="m-0">Join or create a workspace</h3>
          </Header>
        ) : (
          <Header variant="borderless" />
        ))
      }
      {...props}
    >
      {data && (
        <>
          {!invitationsOrWorkspaces && (
            <div className="relative" style={{ marginTop: -48 }}>
              <div
                className={tw(
                  "bg-center bg-cover border-interactive-ghost md:bg-contain md:border-b-1",
                  "before:block before:pt-[100%] before:content-[''] md:before:pt-[calc(100%_*_(226/640))]"
                )}
                style={{
                  backgroundImage: `url(${CreateIllustrationWorkspaces})`,
                }}
              />
            </div>
          )}

          <Main className="flex flex-col px-16 md:px-32">
            {invitationsOrWorkspaces ? (
              <div
                className={tw(
                  "py-16 mb-16 border-b-1 border-background-subtle"
                )}
              >
                {workspacesDescription}
              </div>
            ) : (
              <div className="">
                <h3 className="mt-16 mb-12">Create a Workspace</h3>
                <p className="mt-0 mb-22">{workspacesDescription}</p>
              </div>
            )}

            {invitationSections}
            {workspacesAvailable}

            <InformationBubble
              className="mb-16"
              iconProps={{ className: "text-interactive-primary" }}
            >
              Looking for a different workspace?
              <br />
              Sign in with another email or ask for an invite.
            </InformationBubble>
          </Main>
          <Footer
            className="flex-col-reverse"
            variant={invitationsOrWorkspaces ? "bordered" : "borderless"}
          >
            {invitationsOrWorkspaces ? (
              <>
                <Button
                  buttonStyle={onboarding ? "simpleSecondary" : "primary"}
                  className={
                    onboarding ? "mr-10 bg-background-ghost" : undefined
                  }
                  icon="Plus"
                  onClick={openCreateWorkspaceModal}
                >
                  Create
                </Button>
                {onboarding && (
                  <Button buttonStyle="primary" onClick={handleCloseModal}>
                    Finish
                  </Button>
                )}
              </>
            ) : (
              <>
                {onboarding && (
                  <Button buttonStyle="primary" onClick={handleCloseModal}>
                    Finish
                  </Button>
                )}
                <Button
                  buttonStyle={onboarding ? "simpleSecondary" : "primary"}
                  className={
                    onboarding ? "mr-10 bg-background-ghost" : undefined
                  }
                  icon="Plus"
                  onClick={openCreateWorkspaceModal}
                >
                  Create
                </Button>
              </>
            )}
          </Footer>
        </>
      )}
    </StandardModal>
  );
};

export default WorkspaceModalJoin;
