import { ComponentProps, memo } from "react";

import { useApolloClient } from "@apollo/client";
import { isEqual } from "lodash-es";

import analytics from "analytics";
import {
  ThreadActionOrigin,
  ThreadArchivedUnarchived,
  ThreadBulkArchivedUnarchived,
  ThreadBulkMarkedReadUnread,
  ThreadBulkStarredUnstarred,
  ThreadBulkSubscribedUnsubscribed,
  ThreadMarkedReadUnread,
  ThreadStarredUnstarred,
  ThreadSubscribedUnsubscribed,
} from "analytics/events/thread";
import { readThreadList } from "apollo/cache/threadHelpers";
import { Button } from "components/design-system/Button";
import { ThreadSubscription } from "generated/graphql";
import useInboxThreadActions, {
  ThreadSelection,
} from "hooks/thread/useInboxThreadActions";
import tw from "utils/tw";

import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import breakpoints from "utils/breakpoints";

type Props = {
  bulkEditMode?: boolean;
  buttonClassName?: string;
  buttonStyle?: ComponentProps<typeof Button>["buttonStyle"];
  canArchive?: boolean;
  exitBulkEditMode?: () => void;
  selection: ThreadSelection;
};

type PropsMemo = Omit<Props, "canArchive" | "bulkEditMode"> & {
  canArchive: boolean;
  bulkEditMode: boolean;
};

const useInboxHandlers = ({
  bulkEditMode,
  exitBulkEditMode,
  selection,
}: Props) => {
  const cache = useApolloClient().cache;
  const inboxThreadActions = useInboxThreadActions();

  const threadEdges =
    selection.selectMode === "all"
      ? readThreadList(selection.filter, cache)?.threads.edges.filter(
          e => !selection.excludedEdgeIDs.has(e.id)
        )
      : selection.threadEdges;

  if (!threadEdges) return {};

  const archived =
    threadEdges.filter(e => e.isArchived).length !== threadEdges.length;
  const read = threadEdges.filter(e => e.isRead).length !== threadEdges.length;
  const starred =
    threadEdges.filter(e => e.isStarred).length !== threadEdges.length;
  const subscribed =
    threadEdges.filter(threadEdge => isSubscribed(threadEdge)).length === 0;

  const threadId = threadEdges[0]?.id.split("-")[0] ?? "";

  const onClickArchive = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (bulkEditMode) {
      inboxThreadActions.markThreadEdges({ archived, selection });
      if (selection.filter?.mailbox === "inbox") exitBulkEditMode?.();
      analytics.track(ThreadBulkArchivedUnarchived, {
        archived,
        count: threadEdges.length,
      });
      return;
    }

    if (!threadEdges[0]) return;

    inboxThreadActions.toggleThreadArchived(threadEdges[0]);

    analytics.track(ThreadArchivedUnarchived, {
      archived,
      threadId,
      uiOrigin: ThreadActionOrigin.ListItem,
    });
  };

  const onClickFollow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (bulkEditMode) {
      const subscription = subscribed
        ? ThreadSubscription.Inbox
        : ThreadSubscription.Archive;
      inboxThreadActions.markThreadEdges({ selection, subscription });
      if (selection.filter?.mailbox === "inbox") exitBulkEditMode?.();
      analytics.track(ThreadBulkSubscribedUnsubscribed, {
        count: threadEdges.length,
        subscribed,
      });
      return;
    }

    if (!threadEdges[0]) return;

    inboxThreadActions.toggleThreadSubscribed(threadEdges[0]);

    analytics.track(ThreadSubscribedUnsubscribed, {
      subscribed,
      threadId,
      uiOrigin: ThreadActionOrigin.ListItem,
    });
  };

  const onClickStar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (bulkEditMode) {
      inboxThreadActions.markThreadEdges({ selection, starred });
      analytics.track(ThreadBulkStarredUnstarred, {
        count: threadEdges.length,
        starred,
      });
      return;
    }

    if (!threadEdges[0]) return;

    inboxThreadActions.toggleThreadStarred(threadEdges[0]);

    analytics.track(ThreadStarredUnstarred, {
      starred,
      threadId,
      uiOrigin: ThreadActionOrigin.ListItem,
    });
  };

  const onClickMarkRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (bulkEditMode) {
      inboxThreadActions.markThreadEdges({ read, selection });
      analytics.track(ThreadBulkMarkedReadUnread, {
        count: threadEdges.length,
        read,
      });
      return;
    }

    if (!threadEdges[0]) return;

    inboxThreadActions.toggleThreadRead(threadEdges[0]);

    analytics.track(ThreadMarkedReadUnread, {
      read,
      threadId,
      uiOrigin: ThreadActionOrigin.ListItem,
    });
  };

  return {
    archived,
    onClickArchive,
    onClickFollow,
    onClickMarkRead,
    onClickStar,
    read,
    starred,
    subscribed,
    threadEdges,
  };
};

const InboxThreadActions = ({
  canArchive = true,
  bulkEditMode = false,
  buttonClassName,
  buttonStyle,
  exitBulkEditMode,
  selection,
}: Props): JSX.Element | null => (
  <MemoizedInboxThreadActions
    canArchive={canArchive}
    bulkEditMode={bulkEditMode}
    buttonClassName={buttonClassName}
    buttonStyle={buttonStyle}
    exitBulkEditMode={exitBulkEditMode}
    selection={selection}
  />
);

const MemoizedInboxThreadActions = memo(
  ({
    canArchive,
    bulkEditMode,
    buttonClassName,
    buttonStyle = "subtle",
    exitBulkEditMode,
    selection,
  }: PropsMemo): JSX.Element | null => {
    const {
      archived,
      onClickArchive,
      onClickFollow,
      onClickMarkRead,
      onClickStar,
      read,
      starred,
      subscribed,
      threadEdges,
    } = useInboxHandlers({ bulkEditMode, exitBulkEditMode, selection });

    if (!threadEdges) return null;

    const wordThread = threadEdges.length > 1 ? "threads" : "thread";

    const className = bulkEditMode
      ? "inbox-thread-action btn-hover-grow mr-8 last:mr-0 px-8 py-15 md:px-2 md:py-6"
      : "inbox-thread-action btn-hover-grow h-32 w-32";

    return (
      <div className="flex items-center">
        <Button
          buttonStyle={buttonStyle}
          buttonType="none"
          className={tw(className, buttonClassName)}
          icon={subscribed ? "Bell" : "BellSmallFilled"}
          iconSize={breakpoints().md ? 20 : 24}
          onClick={onClickFollow}
          onPointerDown={e => e.stopPropagation()}
          tooltip={
            subscribed ? `Follow ${wordThread}` : `Unfollow ${wordThread}`
          }
          type="button"
        />

        <Button
          buttonStyle={buttonStyle}
          buttonType="none"
          className={tw(className, buttonClassName, {
            unstar: !starred,
          })}
          icon={starred ? "Star" : "StarFilled"}
          iconSize={breakpoints().md ? 20 : 24}
          onClick={onClickStar}
          onPointerDown={e => e.stopPropagation()}
          tooltip={`${!starred ? "Unstar" : "Star"} ${wordThread}`}
          type="button"
        />

        <Button
          buttonStyle={buttonStyle}
          buttonType="none"
          className={tw(className, buttonClassName)}
          icon={!read ? "Unread" : "Read"}
          iconSize={breakpoints().md ? 20 : 24}
          onClick={onClickMarkRead}
          onPointerDown={e => e.stopPropagation()}
          tooltip={`${!read ? "Mark unread" : "Mark read"}`}
          type="button"
        />

        {canArchive ? (
          <Button
            buttonStyle={buttonStyle}
            buttonType="none"
            className={tw(className, buttonClassName)}
            icon={!archived ? "Inbox" : "Check"}
            iconSize={breakpoints().md ? 20 : 24}
            onClick={onClickArchive}
            onPointerDown={e => e.stopPropagation()}
            tooltip={!archived ? "Move to inbox" : `Archive ${wordThread}`}
            type="button"
          />
        ) : null}
      </div>
    );
  },
  (prev, next): boolean =>
    prev.canArchive === next.canArchive &&
    isEqual(prev.selection, next.selection)
);

export default InboxThreadActions;
