import { ExternalObject } from "@utility-types";
import getRandomInt from "utils/getRandomInt";
import { matchURL } from "utils/matchURL";
import { trimUrl } from "utils/trimUrl";

import { Image as ImageElement } from "components/Media";
import { Hyperlink } from "components/MessageElements";
import { Skeleton } from "components/Skeleton";
import { Icon } from "components/design-system/icons";
import glueImageURL from "utils/glueImageURL";

type Props = {
  item: ExternalObject & { state: "loading" | "failed" | "finished" };
};

const LinkPreview = ({ item }: Props): JSX.Element => {
  const styles =
    item.state === "failed"
      ? { border: "border-accent-error", text: "text-accent-error" }
      : {
          border: "border-border-container hover:border-border-container-hover",
          text: "text-text-strong",
        };

  const image = item.image?.[0];

  const imageUrl = matchURL(image?.url || "");

  return (
    <div
      className={`group/link-preview h-56 overflow-hidden relative rounded-lg select-none border ${styles.border}`}
    >
      <Hyperlink
        className="flex flex-row items-center h-full text-text-primary hover:no-underline"
        url={item.url}
      >
        {(item.state === "loading" || item.state === "failed" || imageUrl) && (
          <ImageElement
            alt={""}
            /* istanbul ignore next */
            blurHash={image?.blurHash}
            className="object-cover w-full h-full"
            figureClassName="h-full w-56 flex-shrink-0"
            resize={{ fit: "max", h: 80 }}
            src={imageUrl}
          />
        )}
        <div className="flex flex-col px-12 py-7 w-full">
          <div
            className={`group-hover/link-preview:underline line-clamp-1 text-body-bold ${styles.text}`}
          >
            {item.state === "loading" ? (
              <Skeleton height="18px" width={`${getRandomInt(50, 90)}%`} />
            ) : item.state === "failed" ? (
              "Unable to load preview"
            ) : (
              <>
                {item.icon && (
                  <img
                    alt="favicon"
                    className="inline mr-8 rounded-sm shrink-0 w-16 h-16"
                    src={glueImageURL(item.icon.url)}
                  />
                )}
                {item.title}
              </>
            )}
          </div>

          <div className="flex items-center text-text-subtle">
            <div className="p-2 mt-2">
              <Icon
                icon="Link"
                className="shrink-0"
                size="12"
                strokeWidth="1"
              />
            </div>
            <span className="ml-8 min-w-0 text-footnote truncate">
              {trimUrl(item.url)}
            </span>
          </div>
        </div>
      </Hyperlink>
    </div>
  );
};

export default LinkPreview;
