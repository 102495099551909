import { useCallback, useEffect, useState } from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";

import { Workspace } from "@utility-types";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { Button } from "components/design-system/Button";
import GroupModalCreate from "components/group/GroupModal/GroupModalCreate";
import useModalStore from "store/useModalStore";
import getRandomInt from "utils/getRandomInt";

import ListHeaderWithAction from "components/ListHeaderWithAction";
import { Skeleton } from "components/Skeleton";

import WorkspaceGroupItem from "./groups/WorkspaceGroupItem";

type Props = {
  loadGroups: (cursor: string) => void;
  loading: boolean;
  workspace?: Workspace;
};

const WorkspaceGroups = ({
  loadGroups,
  loading,
  workspace,
}: Props): JSX.Element => {
  const [hasNextPage, setHasNextPage] = useState(false);
  const [afterCursor, setAfterCursor] = useState<string | null>();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openCreateGroupModal = useCallback(() => {
    if (!workspace) return;

    openModal(<GroupModalCreate workspaceID={workspace.id} />);
  }, [openModal, workspace]);

  useEffect(() => {
    if (!workspace) return;

    setHasNextPage(workspace.groups.pageInfo.hasNextPage);
    setAfterCursor(workspace.groups.pageInfo.endCursor);
  }, [workspace]);

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      if (!afterCursor) return;
      loadGroups(afterCursor);
    }, [afterCursor, loadGroups]),
    rootMargin: "0px 0px 200px 0px",
  });

  const totalGroups = workspace?.groups?.totalCount;
  const skeletons = (
    <div className="flex flex-col justify-center items-start">
      {cloneElementForSkeletons(
        <div className="flex items-center mt-20">
          <div className="flex flex-col">
            <Skeleton width={`${getRandomInt(200, 250)}px`} />
            <Skeleton width={`${getRandomInt(100, 200)}px`} />
          </div>
          <Skeleton className="ml-100" width={`${getRandomInt(50, 100)}px`} />
        </div>,
        5
      )}
    </div>
  );

  return (
    <div ref={listRef} className="native:pb-safe-area overflow-y-auto py-16">
      <ListHeaderWithAction
        button={
          workspace && (
            <Button
              buttonStyle="simplePrimary"
              buttonType="text"
              icon="Plus"
              iconClassName="mr-3"
              iconSize={15}
              iconStroke={3}
              onClick={openCreateGroupModal}
            >
              Create new group
            </Button>
          )
        }
      >
        <div className="text-base font-bold">
          {workspace ? (totalGroups || 0) + 1 : <Skeleton width="15px" />}
          &nbsp;groups
        </div>
      </ListHeaderWithAction>

      {workspace ? (
        <ul className="space-y-16">
          <WorkspaceGroupItem group={workspace} />
          {workspace.groups.edges.map(group => (
            <WorkspaceGroupItem key={group.node.id} group={group.node} />
          ))}
        </ul>
      ) : (
        skeletons
      )}
      {hasNextPage && (
        <div ref={scrollSentryRef} className="mt-10 text-text-subtle">
          Loading…
        </div>
      )}
    </div>
  );
};

export default WorkspaceGroups;
