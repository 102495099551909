import {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useApolloClient } from "@apollo/client";
import { useAbortController } from "use-abort-controller-hook";

import { StreamFileAttachment } from "@utility-types";
import ProgressModal from "components/Modals/ProgressModal";
import { Button } from "components/design-system/Button";
import {
  FetchBoxFilePreviewDocument,
  FetchBoxFilePreviewQuery,
} from "generated/graphql";
import {
  LayerProvider,
  LayerStackPriority,
  useLayerState,
} from "providers/LayerProvider";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";
import useProgressStore from "store/useProgressStore";
import getFormattedFileSizeString from "utils/getFormattedFileSizeString";
import { isNativeMobile } from "utils/platform";
import shareFileNativeMobile from "utils/shareFileNativeMobile";

import { Portal } from "components/Portal";
import { Icon } from "components/design-system/icons";
import { FileIcon, IFrame } from "components/helper";
import tw from "utils/tw";

type Props = {
  files: StreamFileAttachment[];
};

type BoxData = {
  boxAccessToken: string;
  boxFileID: string;
};

const EscHandler = ({ destroy }: { destroy: () => void }) => {
  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (!layerIsActive) return;
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();

        destroy();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [destroy, layerIsActive]);

  return null;
};

function File({ files }: Props): JSX.Element {
  const getFilePreviewData = useGetFilePreviewData();
  const boxModalRef = useRef<HTMLDivElement>(null);
  const boxModalHeaderRef = useRef<HTMLDivElement>(null);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [boxData, setBoxData] = useState<BoxData[]>();
  const fileIds = useRef<Map<string, { boxId?: string; glueId: string }>>(
    new Map()
  );
  const [currentFile, setCurrentFile] = useState<{
    boxId?: string;
    downloadUrl?: string;
    glueId?: string;
    isFirst: boolean;
    isLast?: boolean;
    title: string;
  }>();
  const refCurrentIndex = useRef<number>();
  const previewInstanceRef = useRef<Preview>();
  const { openSnackbar } = useSnackbar();
  const destroy = useCallback(() => {
    refCurrentIndex.current = undefined;
    setCurrentFile(undefined);
    setBoxData(undefined);
    previewInstanceRef.current?.hide();
    previewInstanceRef.current = undefined;
  }, []);
  const abortController = useAbortController();
  const firstRenderRef = useRef(true);
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const showProgress = () => {
    openModal(
      <ProgressModal
        autoCloseDelay={0}
        header={"Downloading File"}
        onCancel={abortController.abort}
      />
    );
  };

  const getBoxData = useCallback(
    (fileId: string) => {
      const fileIdsArray = [...fileIds.current.values()];
      try {
        return Promise.all(
          [
            ...[
              ...fileIdsArray.splice(
                fileIdsArray.findIndex(({ glueId }) => glueId === fileId)
              ),
              ...fileIdsArray,
            ].map(({ glueId: glueId_1 }) => glueId_1),
          ].map(id =>
            new Promise<{
              boxAccessToken: string;
              boxFileID: string;
            }>((resolve, _reject) => {
              getFilePreviewData({ id }).then(boxData => {
                fileIds.current.set(id, {
                  boxId: boxData.boxFileID,
                  glueId: id,
                });
                resolve(boxData);
              });
            }).catch(error => {
              console.warn("Error: [FetchBoxFilePreviewQuery] -", error);
              return undefined;
            })
          )
        );
      } catch (error) {
        console.warn("Error: [attachment file] -", error);
        return [undefined];
      }
    },
    [getFilePreviewData]
  );

  useEffect(() => {
    if (!firstRenderRef.current) return;
    firstRenderRef.current = false;
    const firstRender = firstRenderRef.current;

    return () => {
      !firstRender && abortController.abort();
    };
  }, [abortController]);
  useEffect(() => {
    const Box = iFrameRef.current?.contentWindow?.Box;
    if (Box && !previewInstanceRef.current) {
      previewInstanceRef.current = new Box.Preview();
    }
    const { current: container } = boxModalRef;
    const { current: preview } = previewInstanceRef;

    if (!preview || !container || !boxData || boxData.length === 0) return;
    const collection = boxData.map(file => file.boxFileID);
    const update = (boxId: string) => {
      const glueId = [...fileIds.current.values()]
        .filter(file => file.boxId && file.boxId === boxId)
        .map(file => file.glueId)[0];
      const file = files.find(({ file_id }) => file_id === glueId);
      setCurrentFile({
        boxId,
        downloadUrl: file?.asset_url,
        glueId: file?.file_id,
        isFirst: preview.collection?.[0] === boxId,
        isLast: preview.collection?.pop() === boxId,
        title: file?.title || "",
      });
      preview.updateCollection(collection);
    };
    const loadHandler = ({ file: { id: boxId } }: { file: { id: string } }) => {
      update(boxId);
    };
    const navigateHandler = (boxId: string) => {
      refCurrentIndex.current = collection.findIndex(file => file === boxId);
      update(boxId);
    };

    preview.addListener("load", loadHandler);
    preview.addListener("navigate", navigateHandler);
    if (refCurrentIndex.current) return;
    preview.show(
      boxData[0]?.boxFileID ?? "",
      () =>
        Promise.resolve(
          Object.fromEntries(
            boxData.map(({ boxAccessToken, boxFileID }) => [
              boxFileID,
              boxAccessToken,
            ])
          )
        ),
      {
        collection,
        container,
        header: "none",
        showDownload: true,
      }
    );
  }, [boxData, files]);

  return (
    <div className="file-attachment overflow max-w-full">
      <Portal>
        <LayerProvider
          id={LayerStackPriority[LayerStackPriority.Topmost]}
          isActive={!!currentFile}
          zIndex={LayerStackPriority.Topmost}
        >
          {currentFile && (
            <div
              className={
                "box-file-preview fixed bg-background-black/80 top-0 left-0 w-full h-full"
              }
              onClick={() => {
                destroy();
              }}
              style={
                {
                  "--header-height": `${boxModalHeaderRef.current?.offsetHeight}px`,
                } as CSSProperties
              }
            >
              <header
                ref={boxModalHeaderRef}
                className="flex justify-between w-full"
              >
                <div className="flex items-center px-15 text-sm text-interactive-ghost">
                  {refCurrentIndex.current !== undefined &&
                    `${refCurrentIndex.current + 1} / ${fileIds.current.size}`}
                </div>
                <div className="flex" onClick={e => e.stopPropagation()}>
                  <Button
                    buttonStyle="none"
                    // TODO: fix these colors waiting for design - semantic names are off
                    className="hover:text-ghost-accent text-interactive-subtle-disabled disabled:text-interactive-subtle-hover p-10 h-44 w-44"
                    disabled={!!currentFile.isFirst}
                    icon="ArrowLeft"
                    iconSize={24}
                    onClick={() => {
                      previewInstanceRef.current?.navigateLeft();
                    }}
                    type="button"
                  />

                  <Button
                    buttonStyle="none"
                    // TODO: fix these colors waiting for design - semantic names are off
                    className="hover:text-ghost-accent text-interactive-subtle-disabled disabled:text-interactive-subtle-hover p-10 h-44 w-44"
                    disabled={!!currentFile.isLast}
                    icon="ArrowRight"
                    iconSize={24}
                    onClick={() => {
                      previewInstanceRef.current?.navigateRight();
                    }}
                    type="button"
                  />

                  {isNativeMobile() ? (
                    <Button
                      buttonStyle="none"
                      // TODO: fix these colors waiting for design - semantic names are off
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      icon="Download"
                      iconSize={22}
                      onClick={() => {
                        if (!currentFile.downloadUrl) return;

                        shareFileNativeMobile({
                          abortSignal: abortController.signal,
                          dialogTitle: "Share file",
                          fileName: currentFile.title,
                          onError: () => {
                            useProgressStore.setState({
                              message: {
                                text: "Unknown error while sharing file.",
                                type: "error",
                              },
                            });
                          },
                          showProgress,
                          title: currentFile.title,
                          url: currentFile.downloadUrl,
                        });
                      }}
                      type="button"
                    />
                  ) : (
                    <a
                      // TODO: fix these colors waiting for design - semantic names are off
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      href={currentFile.downloadUrl}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Icon icon="Download" size={22} />
                    </a>
                  )}
                  {!isNativeMobile() && (
                    <Button
                      buttonStyle="none"
                      className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                      icon="Printer"
                      // TODO: fix these colors waiting for design - semantic names are off
                      iconSize={22}
                      onClick={() => {
                        previewInstanceRef.current?.print();
                      }}
                      type="button"
                    />
                  )}
                  <Button
                    buttonStyle="none"
                    className="hover:text-ghost-accent text-interactive-subtle-disabled p-10 h-44 w-44"
                    // TODO: fix these colors waiting for design - semantic names are off
                    icon="Close"
                    iconSize={24}
                    onClick={destroy}
                    type="button"
                  />
                  <EscHandler destroy={destroy} />
                </div>
              </header>
              {!boxModalHeaderRef.current ? (
                <div className="flex fixed top-0 left-0 justify-center items-center w-full h-full text-base font-normal text-interactive-ghost">
                  <p>Loading...</p>
                </div>
              ) : null}
              <IFrame
                ref={iFrameRef}
                className={`w-full h-full md:max-w-4xl mx-auto md:px-20${
                  !boxModalHeaderRef.current ? " opacity-0" : ""
                }`}
                headElements={[
                  {
                    type: "link",
                    values: {
                      url: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=block",
                    },
                  },
                  {
                    type: "link",
                    values: {
                      url: "https://cdn01.boxcdn.net/platform/preview/2.78.0/en-US/preview.css",
                    },
                  },
                  {
                    type: "script",
                    values: {
                      url: "https://cdn01.boxcdn.net/platform/preview/2.78.0/en-US/preview.js",
                    },
                  },
                  {
                    type: "inlineStyle",
                    values: {
                      text: `html,
                      body, 
                      body > div, 
                      .box-modal {
                        font-family: 'Lato', sans-serif;
                        width: 100vw;
                        height: 100vh;
                        margin: 0;
                      }
                      button.bp-navigate {
                        display: none!important;
                      }
                      .loading {
                        display:flex;
                        justify-content: center;
                        font-size: 15px;
                        line-height: 18px;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        position: fixed;
                        top:0; 
                        left:-0.5px;
                        color: rgba(227, 229, 232, 1);
                      }`,
                    },
                  },
                ]}
                title="file-preview"
              >
                <div
                  ref={boxModalRef}
                  className="box-modal"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="loading" onClick={destroy}>
                    <p>Loading...</p>
                  </div>
                </div>
              </IFrame>
              <footer className="flex justify-center items-center w-full">
                <div className="file-title text-center text-interactive-ghost truncate">
                  {currentFile.title}
                </div>
              </footer>
            </div>
          )}
        </LayerProvider>
      </Portal>
      <div className="flex flex-wrap">
        {files.map(
          ({
            asset_url: assetUrl,
            file_id: fileId,
            file_size: fileSize,
            mime_type: mimeType,
            previewable,
            title,
          }) => {
            if (previewable) {
              const boxId = fileIds.current.get(fileId)?.boxId;
              fileIds.current.set(fileId, { boxId, glueId: fileId });
            }
            return (
              <div
                key={fileId}
                className={tw(
                  "flex items-center max-w-full overflow-hidden rounded-lg",
                  "border border-border-container hover:border-border-container-hover h-56 my-6 mr-8",
                  previewable ? " cursor-pointer" : " cursor-default"
                )}
                data-testid="attachment-file"
                onClick={async () => {
                  if (!previewable) {
                    openSnackbar(
                      "error",
                      "Sorry, file isn't previewable.",
                      2000
                    );
                    return;
                  }
                  refCurrentIndex.current = 0;
                  setCurrentFile({ isFirst: true, title });
                  const boxData: (BoxData | undefined)[] =
                    await getBoxData(fileId);
                  setBoxData(boxData.filter((item): item is BoxData => !!item));
                }}
              >
                <div className="flex items-center ml-8">
                  <FileIcon mimeType={mimeType} />
                </div>

                <div className="flex items-center justify-between grow min-w-0 max-w-full overflow-hidden px-12">
                  <span className="overflow-hidden max-w-full">
                    <div className="text-body-bold text-text-primary truncate">
                      {title}
                    </div>
                    <div className="text-footnote text-text-subtle">
                      {getFormattedFileSizeString(fileSize)}
                    </div>
                  </span>
                  <div className="action-buttons hidden shrink-0 pl-8">
                    <a
                      className="hover:text-interactive-subtle-hover p-5 text-interactive-subtle"
                      href={assetUrl}
                      onClick={e => {
                        e.stopPropagation();

                        isNativeMobile() && e.preventDefault();

                        shareFileNativeMobile({
                          abortSignal: abortController.signal,
                          dialogTitle: "Share file",
                          fileName: title,
                          onError: () => {
                            useProgressStore.setState({
                              message: {
                                text: "Unknown error while sharing file.",
                                type: "error",
                              },
                            });
                          },
                          showProgress,
                          title: title,
                          url: assetUrl,
                        });
                      }}
                    >
                      <Icon icon="Download" size={22} />
                    </a>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

function useGetFilePreviewData() {
  const client = useApolloClient();

  return useCallback(
    async (variables: {
      id: string;
    }): Promise<{ boxAccessToken: string; boxFileID: string }> => {
      const { data } = await client.query<FetchBoxFilePreviewQuery>({
        fetchPolicy: "network-only",
        query: FetchBoxFilePreviewDocument,
        variables,
      });
      return data.filePreview;
    },
    [client]
  );
}

export default memo(
  File,
  (prev, next) =>
    prev.files.map(({ file_id }) => file_id).toString() ===
    next.files.map(({ file_id }) => file_id).toString()
);
