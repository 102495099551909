import { Recipient } from "@utility-types";
import { useGetCommonWorkspaces } from "../../hooks/useGetCommonWorkspaces";

/**
 * We get the common workspaces between the recipient and the current user and because of the
 * limited space we have when displaying the combination of badge + label,
 * we only consider the first workspace in common
 */
const useGetRecipientInternalData = (recipient?: Recipient) => {
  const commonWorkspaces = useGetCommonWorkspaces(recipient);

  if (!recipient) {
    return { label: undefined, tooltip: undefined };
  }

  let label = "Internal";
  let tooltip: string | undefined;

  if (recipient.__typename === "User" && recipient.addressDomains?.[0]) {
    if (commonWorkspaces[0]) {
      tooltip = `Part of ${commonWorkspaces[0].name} ${commonWorkspaces[0].domain ? `(${commonWorkspaces[0].domain})` : ""}`;
    }
    label = recipient.addressDomains?.[0];
  }

  if (
    (recipient.__typename === "Group" ||
      recipient.__typename === "GroupPreview") &&
    commonWorkspaces[0]?.domain
  ) {
    label = commonWorkspaces[0]?.domain;
    tooltip = `Part of ${commonWorkspaces[0]?.name} ${commonWorkspaces[0].domain ? `(${commonWorkspaces[0].domain})` : ""}`;
  }

  if (
    recipient.__typename === "Workspace" ||
    recipient.__typename === "WorkspacePreview"
  ) {
    tooltip = "You're a member of this workspace";
    if (recipient.domains?.[0]) {
      label = recipient.domains[0];
    }
  }

  if (label === "Internal" && commonWorkspaces[0]?.name) {
    tooltip = `Part of ${commonWorkspaces[0]?.name}`;
  }

  return { label, tooltip: tooltip ?? label };
};

export default useGetRecipientInternalData;
