import { Pill } from "components/design-system/Pill";
import { useState } from "react";
import tw from "utils/tw";
import SharedListHeader from "./SharedListHeader";
import { FiltersKeys, SharedFilters } from "./types";

const containerClasses = "bg-background-body shadow-level1 rounded-lg";

const Shared = () => {
  const [filter, setFilter] = useState<FiltersKeys>("Recent");

  const Section = ({ children, name }: WithChildren<{ name: FiltersKeys }>) => (
    <>
      {(filter === SharedFilters.Recent || filter === name) && (
        <div className={tw("px-20", containerClasses)}>
          <SharedListHeader
            label={name}
            onShowAll={
              filter === SharedFilters.Recent
                ? () => setFilter(name)
                : undefined
            }
          />
          <div className="h-[168px]">{children}</div>
        </div>
      )}
    </>
  );

  return (
    <div className="flex flex-col grow min-w-0 max-w-[832px] py-16 gap-8 overflow-y-auto">
      <ul className={tw("flex items-center h-52 p-12 gap-8", containerClasses)}>
        {Object.values(SharedFilters).map(pill => (
          <Pill
            key={pill}
            name={pill}
            onClick={setFilter}
            selected={filter === pill}
          />
        ))}
      </ul>
      <div className="flex flex-col gap-8">
        <Section name={SharedFilters.Docs} />
        <Section name={SharedFilters.Media} />
        <Section name={SharedFilters.Links} />
      </div>
    </div>
  );
};

export default Shared;
