import { Recipient } from "@utility-types";
import { useGetRecipientExternalData } from "hooks/useGetRecipientExternalData";
import tw from "utils/tw";
import { Tooltip } from "../../FloatingUi";
import { Icon } from "../../icons";
import useGetRecipientInternalData from "./hooks/useGetRecipientInternalData";

type Props = {
  recipient?: Recipient;
  showLabel?: boolean;
};

const IdentityBadge = ({ recipient, showLabel = true }: Props) => {
  const { label: internalLabel, tooltip: internalTooltip } =
    useGetRecipientInternalData(recipient);

  const { label, groupType, tooltip } = useGetRecipientExternalData(recipient);

  const showExternal = !!label || groupType === "internalWithExternalUsers";
  const showInternal = !label || groupType === "internalWithExternalUsers";

  if (!recipient?.id) return null;

  return (
    <div className="flex items-center">
      {showExternal && (
        <Tooltip content={tooltip} placement="bottom" tooltipStyle="inverted">
          <div className="flex items-center p-2 w-14 h-14 text-icon-warning bg-background-warning rounded-sm select-none">
            <Icon icon="ExternalAccount" size={10} strokeWidth={1} />
          </div>
        </Tooltip>
      )}
      {showInternal && (
        <Tooltip
          content={internalTooltip}
          placement="bottom"
          tooltipStyle="inverted"
          disabled={showExternal}
        >
          <div
            className={tw(
              "flex items-center p-2 w-14 h-14 bg-background-subtle rounded-half text-icon-primary",
              { "ml-6": showExternal }
            )}
          >
            <Icon icon="Check" size={10} strokeWidth={1} />
          </div>
        </Tooltip>
      )}
      {showLabel &&
        ((showExternal && label) || (showInternal && internalLabel)) && (
          <span className="ml-4 text-caption-bold text-text-secondary">
            {showInternal ? internalLabel : label}
          </span>
        )}
    </div>
  );
};

export default IdentityBadge;
